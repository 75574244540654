import React from 'react'
import '../css/Spinner.css'

function Spinner(){

  return (
    <div className="spinner"></div>
  )

}
export default Spinner
